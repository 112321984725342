/* eslint-disable prefer-destructuring */

import PropTypes from 'prop-types';
import { Carousel } from 'react-bootstrap';
import _ from 'lodash';
import variables from '@esolidar/toolkit/build/assets/sass/_export.module.scss';
import Picture from '../../../common/container/Picture';

const Slider = props => {
  let slides;
  let height;

  if (_.isObject(props.data.data)) {
    slides = _.filter(props.data.data.slides, { status: 1 });
    height = props.data.data.height;
  } else {
    slides = _.filter(JSON.parse(props.data.data).slides, { status: 1 });
    height = JSON.parse(props.data.data).height;
  }

  const showControls = slides.length > 1;
  const showIndicators = slides.length > 1;
  const slideHeight = height || 'auto';
  const renderSlides = () => {
    if (slides.length > 0) {
      return slides.map((slide, i) => (
        <Carousel.Item key={i} style={{ height: slideHeight }}>
          {slide.title && <div className="slider-mask" />}
          <Picture
            className="d-block w-100 h-100"
            src={`${process.env.NEXT_PUBLIC_server_less_resize_image}/${slide.image}`}
            alt={slide.title}
          />
          <Carousel.Caption>
            {slide.title && (
              <h3
                style={{
                  color: variables['colors-white'],
                  fontSize: '28px',
                  textAlign: 'right',
                }}
              >
                {slide.title}
              </h3>
            )}
            {slide.text && (
              <p
                style={{
                  color: variables['colors-white'],
                  fontSize: '18px',
                  textAlign: 'right',
                }}
              >
                {slide.text}
              </p>
            )}
          </Carousel.Caption>
        </Carousel.Item>
      ));
    }
  };

  return (
    <Carousel controls={showControls} indicators={showIndicators}>
      {renderSlides()}
    </Carousel>
  );
};

Slider.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default Slider;
