import PropTypes from 'prop-types';
import { isObject } from 'lodash';
import rawDraftToHtml from '@esolidar/toolkit/build/utils/rawDraftToHtml';
import ColorContext from '../../../../contexts/ColorContext';

const TextHtml = props => {
  const data = isObject(props.data.data) ? props.data.data : JSON.parse(props.data.data);
  const isContentHtmlString = typeof data.body === 'string';

  return (
    <ColorContext.Consumer>
      {color => (
        <div>
          <h1 style={{ color: color.primaryColor }}>{data.title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: isContentHtmlString ? data.body : rawDraftToHtml(data.body, data.columns),
            }}
          />
        </div>
      )}
    </ColorContext.Consumer>
  );
};

TextHtml.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
};

export default TextHtml;
