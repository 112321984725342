import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import Button from '@esolidar/toolkit/build/elements/button';
import { toggleLoginModal } from '../../../store/actions/authUser';
import ColorContext from '../../../contexts/ColorContext';

const LoginButtons = () => {
  const [allowUsers, setAllowUsers] = useState(1);
  const dispatch = useDispatch();
  const lang = typeof window !== 'undefined' ? localStorage.getItem('lang') : 'en';

  useEffect(() => {
    setAllowUsers(JSON.parse(localStorage.getItem('config')).allow_member_registration);
  });

  const handleOnClickLogin = () => {
    dispatch(toggleLoginModal({ isOpen: true, nextUrl: `/${lang}/feed` }));
  };

  return (
    <ColorContext.Consumer>
      {color => (
        <div className="container">
          <Row className="about-login-buttons">
            <Col sm={12} className="tex-center">
              {allowUsers === 1 && (
                <Button
                  extraClass="dark mr-2"
                  href="/auth/register"
                  text={<FormattedMessage id="signup" />}
                />
              )}
              <Button
                extraClass="success-full ml-2"
                onClick={handleOnClickLogin}
                text={<FormattedMessage id="login" />}
                style={{
                  backgroundColor: color.primaryColor,
                  borderColor: color.primaryColor,
                }}
              />
            </Col>
          </Row>
        </div>
      )}
    </ColorContext.Consumer>
  );
};
export default LoginButtons;
