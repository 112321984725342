import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import About from '@esolidar/feed/build/views/about/About';
import { getPublicFeed } from '../../../store/actions/feed';
import { RootState } from '../../../store/reducers';
import useUser from '../../../shared/hooks/useUser';
import useCompany from '../../../shared/hooks/useCompany';
import useWhiteLabelConfig from '../../../shared/hooks/useWhiteLabelConfig';

const AboutFeed = (): JSX.Element => {
  const dispatch = useDispatch();
  const feedList = useSelector((state: RootState) => state.feed.feed);
  const whiteLabelConfig = useWhiteLabelConfig();
  const user = useUser();
  const company = useCompany();

  const props = {
    actions: {
      getPublicFeed: (companyId, activePage, postsPerPage) => {
        dispatch(getPublicFeed(companyId, activePage, postsPerPage));
      },
    },
    reducers: {
      company,
      feedList: feedList.data ? feedList.data : {},
      user,
      whiteLabelConfig,
    },
    cdnStaticUrl: process.env.NEXT_PUBLIC_cdn_static_url,
    companyId: company?.id,
  };

  return <About {...props} />;
};

export default AboutFeed;
