import PropTypes from 'prop-types';
import { isArray } from 'lodash';

const Button = ({ data }) => {
  const areButtonsArray = isArray(JSON.parse(data.data));
  const buttonsList = areButtonsArray ? JSON.parse(data.data) : [JSON.parse(data.data)];
  const { align } = buttonsList[0];

  return (
    <div className="container">
      <div className="section" style={{ textAlign: align }}>
        {buttonsList.map((button, index) => (
          <span key={`${index}-${button.text}`}>
            {(!button.disposition || button.disposition === 'horizontal') && (
              <a
                href={button.url}
                className="button-personalized mr-2"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: button.textColor,
                  background: button.bgColor,
                  fontSize: button.fontSize,
                }}
              >
                {button.text}
              </a>
            )}
            {button.disposition === 'vertical' && (
              <div>
                <a
                  href={button.url}
                  className="button-personalized"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: button.textColor,
                    background: button.bgColor,
                    fontSize: button.fontSize,
                  }}
                >
                  {button.text}
                </a>
              </div>
            )}
          </span>
        ))}
      </div>
    </div>
  );
};

Button.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.string,
  }),
};

export default Button;
