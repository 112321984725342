import { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import Masonry from 'react-masonry-component';
import Carousel, { Modal, ModalGateway } from 'react-images';
import filter from 'lodash/filter';
import Picture from '../../../common/container/Picture';

export const Header = props => (
  <div className="lightbox-header">
    <button onClick={props.modalProps.onClose} className="close-lightbox">
      x
    </button>
    {props.data.title && <strong>{props.data.title}</strong>}
    {props.data.title && <br />}
    {props.data.text}
  </div>
);

class Gallery extends Component {
  state = {
    modalIsOpen: false,
    selectedIndex: 0,
  };

  toggleModal = selectedIndex => {
    this.setState(state => ({
      modalIsOpen: !state.modalIsOpen,
      selectedIndex,
    }));
  };

  render() {
    const masonryOptions = {
      transitionDuration: 0,
    };
    const imagesLoadedOptions = { background: '.my-bg-image-el' };
    const { images } = JSON.parse(this.props.data.data);
    const activeImages = filter(images, { status: 1 });
    const { modalIsOpen, selectedIndex } = this.state;

    const fullpathImages = activeImages.map(image => ({
      src: process.env.NEXT_PUBLIC_cdn_uploads_url
        ? `${process.env.NEXT_PUBLIC_cdn_uploads_url}/${image.image}`
        : image.image,
      title: image.title,
      text: image.text,
    }));

    return (
      <Container>
        <Row>
          <Col sm={12}>
            <Masonry
              options={masonryOptions}
              disableImagesLoaded={false}
              updateOnEachImageLoad={false}
              imagesLoadedOptions={imagesLoadedOptions}
            >
              {activeImages.map((image, i) => (
                <button
                  type="button"
                  key={image.image_id}
                  className="gallery-thumb"
                  onClick={() => this.toggleModal(i)}
                >
                  <Picture
                    src={`${process.env.NEXT_PUBLIC_server_less_resize_image}/${image.image}`}
                    alt={image.title}
                    sizes={[330, 425]}
                  />
                </button>
              ))}
            </Masonry>
          </Col>
        </Row>
        <ModalGateway>
          {modalIsOpen ? (
            <Modal onClose={this.toggleModal}>
              <Carousel
                components={{ Footer: null, Header }}
                currentIndex={selectedIndex}
                views={fullpathImages}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </Container>
    );
  }
}

Gallery.propTypes = {
  data: PropTypes.object,
};

Header.propTypes = {
  data: PropTypes.object,
  modalProps: PropTypes.object,
};

export default Gallery;
