import PropTypes from 'prop-types';

const Picture = ({ src, className, alt, sizes }) => {
  return (
    <picture>
      {sizes
        .sort((a, b) => a - b)
        .map(size => (
          <source key={size} media={`(max-width:${size}px)`} srcSet={`${src}?width=${size}`} />
        ))}
      <source
        media={`(min-width:${Math.max(...sizes)}px)`}
        srcSet={`${src}?width=${Math.max(...sizes)}`}
      />
      <img src={src} alt={alt} className={className} />
    </picture>
  );
};

Picture.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  sizes: PropTypes.array,
};

export default Picture;

Picture.defaultProps = {
  sizes: [330, 425, 650, 768, 1200],
};
