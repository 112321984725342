/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Alert } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import CardNonProfitList from '@esolidar/toolkit/build/components/card/nonProfitList';
import isCompanyAdmin from '@esolidar/toolkit/build/utils/isCompanyAdmin';
import Icon from '@esolidar/toolkit/build/elements/icon';
import Loading from '@esolidar/toolkit/build/components/loading';
import ErrorBoundary from '@esolidar/toolkit/build/components/errorBoundary';
import ButtonToolkit from '@esolidar/toolkit/build/elements/button';
import slugify from '@esolidar/toolkit/build/utils/slugify';
import Props from './About.types';
import { getWhitelabelAboutAsAdmin } from '../../../store/actions/whitelabel';
import changeLangFromUrl from '../../../shared/utils/changeLangFromUrl';
import ColorContext from '../../../contexts/ColorContext';
import PropsContext from '../../../contexts/PropsContext';
import TextHtlm from './types/TextHtml';
import Slider from './types/Slider';
import Gallery from './types/Gallery';
import Video from './types/Video';
import Button from './types/Button';
import LoginButtons from './LoginButtons';
import AboutFeed from '../feed/AboutFeed';
import useUser from '../../../shared/hooks/useUser';

import { RootState } from '../../../store/reducers';

const About: React.FC<Props> = ({ aboutSections }: Props): JSX.Element => {
  const user = useUser();
  const intl = useIntl();
  const context = useContext(PropsContext);
  const { locale } = context;

  const dispatch = useDispatch();
  const whiteLabelAbout = useSelector((state: RootState) => {
    return state.whitelabel.whiteLabelAbout;
  });
  const [aboutSectionsState, setAboutSectionsState] = useState(aboutSections || []);
  const [isLoadingSections, setIsLoadingSections] = useState(!aboutSections);
  const [liveMode, setLiveMode] = useState(false);
  const [adminMode, setAdminMode] = useState(
    typeof window !== 'undefined'
      ? isCompanyAdmin(
          JSON.parse(localStorage.getItem('config')).company.id,
          JSON.parse(localStorage.getItem('user') || '{}')
        )
      : false
  );
  const config = typeof window !== 'undefined' ? localStorage.getItem('config') : null;
  const feedInitiatives = config ? JSON.parse(config).initiatives.posts : null;

  const handleClickThumb = ({ id, name }) => {
    const link = `${process.env.NEXT_PUBLIC_esolidar_url}${locale}/npo/detail/${id}-${slugify(
      name
    )}`;

    window.open(link, '_blank');
  };

  const handleClickViewMore = (id: number) => {
    window.location.href = `/${locale}/donations/npo/${id}`;
  };

  useEffect(() => {
    if (aboutSectionsState.length === 0 && adminMode && !liveMode) {
      dispatch(
        getWhitelabelAboutAsAdmin(
          JSON.parse(typeof window !== 'undefined' ? localStorage.getItem('config') : '{}').id
        )
      );
      setAdminMode(adminMode);
      setLiveMode(true);
      setIsLoadingSections(true);
    }
  }, []);

  useEffect(() => {
    if (whiteLabelAbout.code === 200) {
      setAboutSectionsState(whiteLabelAbout.data);
      setIsLoadingSections(false);
    }
  }, [whiteLabelAbout]);

  const renderAboutSections = () => {
    if (aboutSectionsState.length === 0) {
      return (
        <ColorContext.Consumer>
          {color => (
            <Container className="text-center pt-5 position-relative">
              <img
                src={`${process.env.NEXT_PUBLIC_cdn_static_url}/frontend/white-label/community-under-construction.png`}
                className="w-100"
                alt=""
              />
              <h1
                className="position-absolute"
                style={{
                  color: color.primaryColor,
                  top: '120px',
                  textShadow: 'grey 2px 2px 3px',
                }}
              >
                <FormattedMessage id="about.community.under.construction" />
              </h1>
            </Container>
          )}
        </ColorContext.Consumer>
      );
    }

    if (aboutSectionsState.length > 0) {
      return aboutSectionsState.map(section => {
        if (section.type === 'TEXTHTML') {
          return (
            <div key={section.id} className="container">
              <div className="section">
                <ErrorBoundary>
                  <TextHtlm data={section} />
                </ErrorBoundary>
              </div>
            </div>
          );
        }
        if (section.type === 'SLIDER') {
          return (
            <div key={section.id}>
              <ErrorBoundary>
                <Slider data={section} />
              </ErrorBoundary>
            </div>
          );
        }
        if (section.type === 'GALLERY') {
          return (
            <div key={section.id}>
              <ErrorBoundary>
                <Gallery data={section} />
              </ErrorBoundary>
            </div>
          );
        }
        if (section.type === 'VIDEO') {
          return (
            <div key={section.id}>
              <ErrorBoundary>
                <Video data={section} />
              </ErrorBoundary>
            </div>
          );
        }
        if (section.type === 'BUTTON') {
          return (
            <div key={section.id}>
              <ErrorBoundary>
                <Button data={section} />
              </ErrorBoundary>
            </div>
          );
        }
        if (section.type === 'FEATURED_NPO') {
          return (
            <div key={section.id}>
              <ErrorBoundary>
                <div className="featured-npo container">
                  <div className="featured-npo__header">
                    <div className="featured-npo__title">{JSON.parse(section.data).title}</div>
                    {section.institutions_count > 3 && (
                      <ButtonToolkit
                        ghost
                        text={intl.formatMessage({ id: 'whitelabel.viewmore' })}
                        extraClass="primary-full"
                        iconRight={<Icon name="ArrowRight" />}
                        onClick={() => handleClickViewMore(section.id)}
                        type="button"
                        theme="light"
                      />
                    )}
                  </div>

                  <CardNonProfitList
                    gridType={
                      section.institutions_count === 1
                        ? 'one'
                        : section.institutions_count === 2
                        ? 'two'
                        : 'multi'
                    }
                    onClickDonate={item => handleClickThumb(item)}
                    onClickThumb={item => handleClickThumb(item)}
                    items={section.institutions}
                  />
                </div>
              </ErrorBoundary>
            </div>
          );
        }
      });
    }
  };

  const isLoggedIn = !!user;

  const showFeed = aboutSectionsState.some(
    elm => elm.type === 'FEED' && JSON.parse(elm.data).show === true
  );

  const lang = typeof window !== 'undefined' ? localStorage.getItem('lang') : 'en';
  changeLangFromUrl(lang);

  if (isLoadingSections) return <Loading />;

  return (
    <div className="about-page">
      <div className="about">
        <div>
          {adminMode && (
            <div className="position-sticky" style={{ opacity: '0.7' }}>
              <Alert variant="warning text-center" style={{ margin: '0', padding: '20px 50px' }}>
                <FormattedMessage id="about.livemode.disabled" />
              </Alert>
            </div>
          )}
        </div>
        <div>
          {renderAboutSections()}
          {!isLoggedIn && <LoginButtons />}
        </div>
      </div>
      {showFeed && feedInitiatives && (
        <div className="feed-page" style={{ padding: '0 15px' }}>
          <AboutFeed />
        </div>
      )}
    </div>
  );
};

export default About;
