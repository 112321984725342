import PropTypes from 'prop-types';

const Video = props => {
  const { size, video } = JSON.parse(props.data.data);

  if (video) {
    const vimeo = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
    const youtube = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;

    if (vimeo.test(video)) {
      const videoUrl = video.split('/').reverse()[0];
      return (
        <div className={size === 'fluid' ? 'fluid video' : 'container video'}>
          <iframe
            title="video"
            width="100%"
            src={`https://player.vimeo.com/video/${videoUrl}?color=ffffff&title=0&byline=0&portrait=0&badge=0`}
            frameBorder="0"
          />
        </div>
      );
    }

    if (youtube.test(video)) {
      let videoUrl = '';
      const result = video.split('v=');
      if (result.length > 1) {
        videoUrl = result[1].split('&');
      } else {
        videoUrl = video.split('/').reverse();
      }

      return (
        <div className={size === 'fluid' ? 'fluid video' : 'container video'}>
          <iframe
            frameBorder="0"
            title="video"
            height="100%"
            width="100%"
            src={`https://www.youtube.com/embed/${videoUrl[0]}`}
          />
        </div>
      );
    }

    return <div />;
  }
};

Video.propTypes = {
  data: PropTypes.object,
};

export default Video;
